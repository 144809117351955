const config = {
  app: {
    prefix: "afcd",
    title: "ARM Field Campaigns",
    host: "https://armweb-stage.ornl.gov/afcd",
  },
  auth: {
    cas: "https://armstage-sso.ornl.gov/arm",
    authService: "https://armweb-stage.ornl.gov/Auth/",
    serviceName: "https://armweb-stage.ornl.gov/afcd",
    logout: "https://armstage-sso.ornl.gov/arm/logout",
    sessionExpirationMinutes: 30,
    sessionWarningMinutes: 25,
  },
  graphql: {
    host: "https://armweb-stage.ornl.gov/graphql",
  },
  fieldCampaignDashboardFileApi: {
    host: "https://armweb-stage.ornl.gov/Auth/field-catalog-file-api",
  },
  google: {
    recaptchaKey: "6LdzDTYUAAAAAL05blc17MfL5erA19qPaRfKngtH",
  },
  notification: {
    baseURL: "https://armweb-stage.ornl.gov/NotificationService",
    emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  },
  sso: {
    logout: "https://armstage-sso.ornl.gov/arm/logout",
  },
  profile: {
    baseUrl: "https://armweb-stage.ornl.gov/profile/preference",
    userRegUrl: "https://armweb-stage.ornl.gov/armuserreg",
  },
  plots: {
    baseUrl: "https://plot.dmf.arm.gov/PLOTS/",
    videoThumbBaseUrl: "https://plot.dmf.arm.gov/plotbrowser/thumbs/",
  },
  anomalyDetectionApi: {
    example: "https://anomaly-detection-api.dev.arm.gov/example",
  },
  session: {
    authService: true,
    graphQL: true,
    sessionExpirationMinutes: 30,
    sessionWarningMinutes: 25,
  },
  maintenance: {
    maintenanceToolUrl: "https://armweb-stage.ornl.gov/ARMMaintenanceTools/#",
    pmApiUrl: "https://armweb-stage.ornl.gov/Auth/arm-pmreport-api",
    cmApiUrl: "https://armweb-stage.ornl.gov/Auth/arm-cmreport-api",
    pmApiSearchUrl: "https://armweb-stage.ornl.gov/Auth/arm-pmreport-api/report/search/spec",
    cmApiSearchUrl: "https://armweb-stage.ornl.gov/Auth/arm-cmreport-api/custom/search/spec",
  },
}

export default config
